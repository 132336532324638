<template>
  <div>
    <div class="modal-backdrop fade show"></div>

    <div class="modal fade show d-block bd-example-modal-lg theme-modal" id="quick-view" aria-hidden="true"
      tabindex="-1" role="dialog" aria-labelledby="modal-cartLabel">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content quick-view-modal">
          <div class="modal-body">
            <button class="close btn-close" type="button" @click="closeCompare(false)">
              <span>×</span>
            </button>
            <div class="row quickview-modal">
              <div class="col-lg-6 col-12">
                <div class="quick-view-img">
                  <swiper :slidesPerView="auto" class="swiper-wrapper">
                    <swiper-slide class="swiper-slide" v-for="(imag, index) in productData.images" :key="index">
                      <img :src="getImgUrl(imag)" :id="index" class="img-fluid bg-img" alt="imag.alt" />
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
              <div class="col-sm-2 col-md-4 col-lg-6 rtl-text">
                <div class="product-right">
                  <h2>{{ productData.name[selectedLanguage] }}</h2>
                  <h3 v-if="productData.brandText">
                    {{ productData.brandText }}
                  </h3>
                  <h3>
                    {{ showDecimalValueInLocale(productData.price) }}
                  </h3>

                  <div class="border-product">
                    <h6 class="product-title"> {{ $t('products.retail_price') }}</h6>
                    <p>
                      {{ showDecimalValueInLocale(productData.dvdSuggestedPriceAmount) }}
                    </p>
                  </div>
                  <div class="border-product" v-if="productData.refCli">
                    <h6 class="product-title">Client refrence</h6>
                    <p>
                      {{ productData.refCli }}
                    </p>
                  </div>
                  <div class="product-buttons" v-if="isAuthenticated">

                    <button @click="addToCart(productData.Identifier)" class="btn btn-solid">
                      {{ $t('products.add_to_cart') }} <i class="ti-shopping-cart"></i>
                    </button>
                    <!-- <NuxtLinkLocale :to="{
                      path:
                        '/products/' +
                        productData.Identifier +
                        '/' +
                        productData.name
                          .replaceAll(/[^a-zA-Z0-9]+/g, '-')
                          .toLowerCase(),
                    }" class="btn btn-solid"> -->
                    <NuxtLinkLocale :to="{
                      path:
                        '/products/' +
                        productData.Identifier +
                        '/' +
                        productLinkTitle(productData.name).replaceAll(/[^a-zA-Z0-9]+/g, '-').toLowerCase(),
                    }" class="btn btn-solid">
                      view detail</NuxtLinkLocale>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
// import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { useCartStore } from "~~/store/CartStore";
import { useUtilityStore } from "~/store/UtililityStore";
import { useFilterStore } from "~/store/FilterStore";
import { useI18n } from "vue-i18n";
const props = defineProps(["productData", "isAuthenticated"]);
const utilityStore = useUtilityStore();
const filterStore = useFilterStore();
const selectedLanguage = computed(() => utilityStore.selectedLanguage);

const { t } = useI18n();
const { showDecimalValueInLocale } = useUtils();
const closeCompare = (val) => {
  filterStore.setShowQuickView({ show: false, item: {} })
}
// add to cart
const addToCart = async (id) => {
  const added = await useCartStore().addToCart({
    sku: id,
    qty: 1,
  });
  if (added) {
    //show notification
    useNuxtApp().$showToast({
      msg: t('alerts.added_to_cart.desc'),
      type: "success",
    });
  } else {
    useNuxtApp().$showToast({
      msg: t('alerts.failed_add_cart.desc'),
      type: "error",
    });
  }
}

const getImgUrl = (path) =>
  "https://assets.cld.be/cdn-cgi/image/width=400,height=400quality=75,fit=pad,background=%23FFFFFF/" + path;

const productLinkTitle = computed(() => (name) => name['en_GB'] || name['fr_BE'] || '')
</script>
